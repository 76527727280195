import React from 'react';
import Portfolio from '../components/Portfolio';
import SEO from '../components/seo';
import { useStaticQuery, graphql } from 'gatsby';

import '../styles/main.scss';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		{
			sanitySiteSettings {
				seo {
				metaTitle
				metaDescription
				metaKeywords
				openGraphTitle
				openGraphDescription
				openGraphImage {
					asset {
					url
					}
				}
				twitterTitle
				twitterDescription
				twitterImage {
					asset {
					url
					}
				}
				}
			}
		}
	`);

	const {seo} = data.sanitySiteSettings

	return (
		<React.Fragment>
			<SEO {...seo} />
			<Portfolio/>
		</React.Fragment>

	);
};

export default IndexPage;
